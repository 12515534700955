import { useMemo } from "react";
import { createVerifiedFetch } from "@helia/verified-fetch";
import { REACT_APP_IPFS_GATEWAY } from "conf";

const createIpfsFetcher = async () => {
  const verifiedFetch = await createVerifiedFetch({
    gateways: [REACT_APP_IPFS_GATEWAY || "https://ipfs.io"].filter(Boolean) as string[],
    routers: [],
  });

  async function fetch(cid: string) {
    const response = await verifiedFetch(`ipfs://${cid}`);
    if (!response.ok) {
      console.error(`IPFS fetch failed: ${response.status} ${response.statusText}`);
      throw new Error(response.statusText);
    }
    const res = await response.json();
    return res;
  }

  return fetch;
};

const useIpfsResolver = () => {
  const fetcher = useMemo(createIpfsFetcher, []);

  const load = async (cid: string) => {
    return await fetcher.then((fetch) => fetch(cid));
  };

  const walk = async (cid: string, path: string) => {
    return await load(`${cid}/${path}`);
  };

  return { walk, load };
};

export default useIpfsResolver;

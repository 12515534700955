import { FileObject } from "hooks/useExtractFilesFromUrl";
import { entries } from "lodash/fp";
import yaml from "js-yaml";
import { ContractPackageJson } from "./types";
import convertUint8ArrayToString from "utils/convertUint8ArrayToString";
import { ClaimsTablesConfig } from "./ClaimsTables/types";

export const getContractTemplateDetails = (contractFiles: FileObject) => {
  if (!contractFiles) {
    return null;
  }

  const packageJsonData = entries(contractFiles).find(([file]) => file?.includes("package.json"));

  if (packageJsonData) {
    const packageJson: ContractPackageJson = JSON.parse(convertUint8ArrayToString(packageJsonData[1]));

    return {
      // onchainData,
      engine: packageJson.engines.cweb_interpreter,
      description: packageJson.description,
      keywords: packageJson.keywords?.join(", "),
      homepage: packageJson.homepage,
    };
  }

  return null;
};

export const getContractTemplateOnchainData = (contractFiles: FileObject) => {
  if (!contractFiles) {
    return null;
  }

  const onchainData = entries(contractFiles).find(([file]) => file?.includes("onchain/index.js"));

  if (onchainData) {
    return convertUint8ArrayToString(onchainData[1]);
  }

  return null;
};

export const getContractClaimsTablesConfig = (contractFiles: FileObject): ClaimsTablesConfig | null => {
  if (!contractFiles) {
    return null;
  }

  const claimsTablesData = entries(contractFiles).find(([file]) =>
    file?.includes("offchain/claims-tables/claims-tables.yaml")
  );

  if (claimsTablesData) {
    return yaml.load(convertUint8ArrayToString(claimsTablesData[1])) as ClaimsTablesConfig;
  }

  return null;
};
